import React from "react";
import classes from "./ui.treat";

const textPresets = {
  body: {
    type: "body",
    size: "md",
    lineHeight: "tight",
  },
  uppercase: {
    type: "uppercase",
    size: "md",
    lineHeight: "tight",
  },
  bold: {
    type: "bold",
    size: "md",
    lineHeight: "tight",
  },
};

const XText = React.forwardRef((props, ref) => {
  const preset = textPresets[props.preset] || textPresets.body;
  const {
    as: Comp = "div",
    preset: _,
    color = "black",
    size = preset.size || "",
    type = preset.type,
    lineHeight = preset.lineHeight,
    noOverflow,
    className,
    forceWrap,
    align,
    ...rest
  } = props;
  const classNames = [classes.fontSize[size], classes.textTypes[type]];
  if (className) classNames.push(className);
  if (lineHeight) classNames.push(classes.lineHeight[lineHeight]);
  if (align) classNames.push(classes.textAlign[align]);
  if (noOverflow) classNames.push(classes.noOverflow);
  if (forceWrap) classNames.push(classes.forceWrap);
  if (color) classNames.push(classes.color[color]);
  return <Comp ref={ref} className={classNames.join(" ")} {...rest} />;
});

export default XText;
