import {useTransition} from "react-spring";

export const springConfigs = {
  quick: {tension: 500, friction: 30},
  quickNoBounce: {tension: 600, friction: 44},
};

export const useReveal = (show, {from = {value: 0}, enter = {value: 1}, ...opts} = {}) => {
  const transition = useTransition(show, {
    key: (i) => !!i,
    from,
    enter,
    leave: from,
    ...opts,
  });
  return (renderFn) => transition((props, item) => (item ? renderFn(props, item) : null));
};
