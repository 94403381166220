const getRawStorage = (key, storageGetter) => {
  let storage;
  try {
    storage = storageGetter();
  } catch {
    return {
      get() {
        return null;
      },
      set() {
        return false;
      },
      remove() {
        return false;
      },
    };
  }
  return {
    get() {
      try {
        const content = storage.getItem(key);
        return content ? JSON.parse(content) : null;
      } catch (e) {
        return null;
      }
    },

    set(val) {
      try {
        storage.setItem(key, JSON.stringify(val));
        return true;
      } catch (e) {
        return false;
      }
    },

    remove() {
      try {
        storage.removeItem(key);
        return true;
      } catch (e) {
        return false;
      }
    },
  };
};

export const singleKeyLocalStorage = (key) => getRawStorage(key, () => window.localStorage);
