import React from "react";
import LinkOrButton from "./LinkOrButton";
import {useButtonBehaviour} from "./ButtonBehaviour";
import {rawButtonStyle, plainButtonStyles} from "./button.treat";
import cx from "../cx";
import uiClasses from "./ui.treat";

const PlainButton = React.forwardRef(
  ({color = "default", size = "md", square, full, className, active, ...rest}, ref) => {
    const buttonProps = useButtonBehaviour(rest);
    return (
      <LinkOrButton
        ref={ref}
        className={cx(
          rawButtonStyle,
          uiClasses.textTypes.bold,
          plainButtonStyles.default,
          rest.disabled && plainButtonStyles.disabled,
          plainButtonStyles.colors[color],
          plainButtonStyles.sizes[size],
          square && plainButtonStyles.squarePadding[size],
          full && plainButtonStyles.full,
          className
        )}
        {...buttonProps}
      />
    );
  }
);

export default PlainButton;
