import React from "react";

export const useButtonBehaviour = ({onClick, disabled, ...rest}) => {
  const [pending, setPending] = React.useState(false);
  const unmountedRef = React.useRef();
  React.useEffect(
    () => () => {
      unmountedRef.current = true;
    },
    []
  );

  const handleClick = (...args) => {
    const retVal = onClick(...args);
    if (retVal && typeof retVal.then === "function") {
      setPending(true);
      retVal.then(
        () => !unmountedRef.current && setPending(false),
        (error) => {
          // eslint-disable-next-line no-console
          console.error(error);
          if (!unmountedRef.current) setPending(false);
        }
      );
    }
  };

  return {
    disabled: pending ? true : disabled,
    onClick: onClick ? handleClick : undefined,
    ...rest,
  };
};
