export default {
  white: "#fff",
  white_a05: "rgba(255,255,255,0.05)",
  white_a10: "rgba(255,255,255,0.1)",
  white_a25: "rgba(255,255,255,0.25)",

  black: "#000",
  black_a05: "rgba(0,0,0,0.05)",
  black_a10: "rgba(0,0,0,0.1)",
  black_a25: "rgba(0,0,0,0.25)",

  gray900: "#14141A",
  gray800: "#373746",
  gray700: "#525265",
  gray600: "#6D6D83",
  gray500: "#88889D",
  gray400: "#A3A3B7",
  gray300: "#BFBFD1",
  gray200: "#DCDCEA",
  gray100: "#F6F6FF",

  blue900: "#101027",
  blue800: "#2C2C54",
  blue700: "#414171",
  blue600: "#505083",
  blue500: "#65659B",
  blue400: "#7F7FB3",
  blue300: "#9F9FCB",
  blue200: "#C3C3E3",
  blue100: "#EBEBFB",

  green900: "#081503",
  green800: "#203716",
  green700: "#3F5934",
  green600: "#657C5C",
  green500: "#8D9E86",
  green400: "#ADBAA7",
  green300: "#C4CEC0",
  green200: "#DDE3DA",
  green100: "#F5F8F4",

  red: "#EF8C62",
};
