import React from "react";
import {useStaticQuery, graphql, Link} from "gatsby";
import Root from "../Root";
import Strip from "../ui/Strip";
import XRow from "../ui/XRow";
import ROUTES from "../routes";
import XText from "../ui/XText";
import XCol from "../ui/XCol";

const query = graphql`
  {
    sanityGeneral(_id: {eq: "general"}) {
      title
      footerLinks {
        label
        url
      }
    }
  }
`;

const MainLayout = ({children, ...rest}) => {
  const {sanityGeneral: data} = useStaticQuery(query);
  return (
    <Root {...rest}>
      <XCol bg="green200" screenHeight sp={6} fillParent>
        <Strip align="center" bg="green600" py={3} elevation="mdHeavy">
          <XText as={Link} to={ROUTES.home} type="bold" size="xl" color="white" align="center">
            {data.title}
          </XText>
        </Strip>
        {children}
        <XRow sp={4} justify="center" pb={3} pt={4} px={3}>
          {data.footerLinks.map(({label, url}) => (
            <XText as="a" color="green600" size="sm" href={url} key={label}>
              {label}
            </XText>
          ))}
        </XRow>
      </XCol>
    </Root>
  );
};

export default MainLayout;
