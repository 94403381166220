import React from "react";
import {useReveal} from "../hooks/useReveal";
import XCol from "./XCol";
import {animated} from "react-spring";
import {spinnerStyles} from "./spinner.treat";
import cx from "../cx";
import colors from "./colors";

export const RawSpinner = React.forwardRef(({size = 16, style, ...rest}, ref) => (
  <svg
    style={{width: Math.min(150, size), height: Math.min(150, size), ...style}}
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
    stroke={colors.green600}
    {...rest}
    ref={ref}
  >
    <g className={spinnerStyles.g} strokeWidth="4" fill="none">
      <circle strokeOpacity=".25" cx="20" cy="20" r="18" />
      <path d="M38 20A18 18 0 0 0 20 2" />
    </g>
  </svg>
));

export const Spinner = ({size = 75, withCover, coverColor, className, ...rest}) => (
  <XCol
    absolute
    inset="full"
    className={cx(spinnerStyles.container, className)}
    {...rest}
    align="center"
    justify="center"
  >
    <RawSpinner size={size} />
    {withCover && (
      <XCol
        className={spinnerStyles.cover}
        absolute
        inset="full"
        style={coverColor ? {backgroundColor: coverColor} : {}}
      />
    )}
  </XCol>
);

const AnimRawSpinner = animated(RawSpinner);

export const RevealSpinner = ({show, size = 75, withCover, coverColor, className, ...rest}) => {
  const reveal = useReveal(show);
  return reveal((props) => (
    <XCol
      absolute
      inset="full"
      align="center"
      justify="center"
      className={cx(spinnerStyles.container, className)}
      {...rest}
    >
      <AnimRawSpinner
        size={size}
        style={{opacity: props.value, transform: props.value.to((v) => `scale(${v / 2 + 0.5})`)}}
      />
      {withCover && (
        <XCol
          as={animated.div}
          className={spinnerStyles.cover}
          absolute
          inset="full"
          style={{opacity: props.value, backgroundColor: coverColor}}
        />
      )}
    </XCol>
  ));
};
