import React from "react";
import cx from "../cx";
import {inputStyle, readonlyInputStyle} from "./input.treat";
import uiClasses from "./ui.treat";
import RawTextarea from "react-textarea-autosize";

const Input = React.forwardRef(({className, type = "text", ...props}, ref) => (
  <input
    ref={ref}
    type={type}
    className={cx(inputStyle.base, className, uiClasses.fontSize.lg, uiClasses.textTypes.normal)}
    {...props}
  />
));

export const Textarea = React.forwardRef(
  ({showingErrors, onChange, minRows = 1, className, ...rest}, ref) => (
    <RawTextarea
      ref={ref}
      onChange={onChange && ((e) => onChange(e.target.value))}
      className={cx(
        inputStyle.base,
        className,
        uiClasses.fontSize.lg,
        uiClasses.textTypes.normal,
        showingErrors && inputStyle.withErrors,
        rest.readOnly && readonlyInputStyle,
        className
      )}
      minRows={minRows}
      {...rest}
    />
  )
);

export default Input;
