import React, {createContext, useContext, useEffect, useState, del, useRef} from "react";
import qs from "qs";
import {Store, set, get, keys, clear} from "idb-keyval";
import {Spinner} from "../ui/Spinner";
import MiniEvent from "../utils/mini-event";
import {singleKeyLocalStorage} from "./storage";

export const DBCtx = createContext();

const changeListener = new MiniEvent();

const DEMO_ID = "__demo";

export const parseB64 = (str) => {
  try {
    return atob(str);
  } catch {
    return null;
  }
};

const sessionIdStorage = singleKeyLocalStorage("sessionId");

const useSessionId = (location) => {
  const [sessionId, setSessionId] = useState(null);
  const locRef = useRef(location);
  useEffect(() => {
    const {search} = locRef.current;
    const parsed = search && qs.parse(search, {ignoreQueryPrefix: true});
    if (parsed && parsed.id && parseB64(parsed.id)) {
      setSessionId(parsed.id);
      sessionIdStorage.set(parsed.id);
    } else {
      setSessionId(sessionIdStorage.get() || DEMO_ID);
    }
  }, []);
  return sessionId;
};

export const DBProvider = ({children, location}) => {
  const [db, setDb] = useState();
  const sessionId = useSessionId(location);
  useEffect(() => {
    if (!sessionId) return;
    const store = new Store(sessionId);
    setDb({
      isDemo: sessionId === "__demo",
      clear: () => clear(store),
      keys: () => keys(store),
      get: (key) => get(key, store),
      set: (key, val) => (val ? set(key, val, store) : del(key, store)),
      sessionId,
    });
  }, [sessionId]);
  return db ? <DBCtx.Provider value={db}>{children}</DBCtx.Provider> : <Spinner />;
};

export const useIsDemo = () => {
  const db = useContext(DBCtx);
  return db.isDemo;
};

export const useGetSessionId = () => {
  const db = useContext(DBCtx);
  return db.useSessionId;
};

export const useTaskKeys = () => {
  const db = useContext(DBCtx);
  const [data, setData] = useState(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    let isCurrent = true;
    db.keys().then((val) => {
      if (isCurrent) {
        setData(val);
        setLoading(false);
      }
    });
    return () => {
      isCurrent = false;
      setLoading(true);
      setData(null);
    };
  }, [db]);

  return [data, isLoading];
};

export const useTaskData = (taskKey, type) => {
  const db = useContext(DBCtx);
  const [data, setData] = useState(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    let unsub = changeListener.addListener(({key, value}) => {
      if (key === `${taskKey}:${type}`) setData(value);
    });
    return () => unsub();
  });

  useEffect(() => {
    let isCurrent = true;
    db.get(taskKey).then((val) => {
      if (isCurrent) {
        if (val && val.type === type) {
          setData(val);
        }
        setLoading(false);
      }
    });
    return () => {
      isCurrent = false;
      setLoading(true);
      setData(null);
    };
  }, [taskKey, type, db]);

  return [data, isLoading];
};

export const useSaveTaskData = (taskKey, type) => {
  const db = useContext(DBCtx);
  return (data) => {
    const value = {...data, type};
    return db.set(taskKey, value).then(() => {
      const key = `${taskKey}:${type}`;
      changeListener.emit({key, value});
    });
  };
};
