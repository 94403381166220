import React, {useEffect, useState, useRef} from "react";
import PlainButton from "../ui/PlainButton";
import {RevealSpinner} from "../ui/Spinner";
import XCol from "../ui/XCol";

const Browser = ({url}) => {
  const frameRef = useRef();
  const [showSpinner, setSpinner] = useState(true);

  useEffect(() => {
    const node = frameRef.current;
    if (node.readyState === "complete") {
      setSpinner(false);
      return;
    }
    const handler = () => setSpinner(false);
    node.addEventListener("load", handler);
    return () => node.removeEventListener("load", handler);
  }, []);

  return (
    <XCol fixed style={{zIndex: 10, top: 0, left: 0}} screenHeight fullWidth bg="green100">
      <XCol py={2} px={3} bg="white" align="center" elevation="mdHeavy" relative>
        <PlainButton to=".">Zurück zum Mulde Lehrbaustein</PlainButton>
      </XCol>
      <XCol relative fillParent>
        <RevealSpinner show={showSpinner} withCover />
        <XCol
          fillParent
          as="iframe"
          src={url}
          frameBorder="0"
          border="0"
          cellSpacing="0"
          style={{borderStyle: "none"}}
          ref={frameRef}
        />
      </XCol>
    </XCol>
  );
};

export default Browser;
