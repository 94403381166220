import React from "react";
import XCol from "./XCol";
import {stripStyles} from "./strip.treat";
import cx from "../cx";

const Strip = React.forwardRef(({size = "md", sp, children, fillParent, ...rest}, ref) => (
  <XCol px={4} align="center" fillParent={fillParent} {...rest} ref={ref}>
    <XCol
      sp={sp}
      fillParent={fillParent}
      className={cx(stripStyles.inner, stripStyles.bySize[size])}
    >
      {children}
    </XCol>
  </XCol>
));

export default Strip;
