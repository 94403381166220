import React from "react";
import "./global-styles.treat";
import {Helmet} from "react-helmet";
import Browser from "./components/Browser";
import {DBProvider} from "./models/session";

const Root = ({children, title, location}) => {
  const locState = location.state || {};
  return (
    <>
      <Helmet>
        <title>{title || "Wilde Mulde Lernbaustein"}</title>
      </Helmet>
      <DBProvider location={location}>{children}</DBProvider>
      {locState.browse && <Browser url={locState.browse} />}
    </>
  );
};

export default Root;
