import React from "react";
import urlBuilder from "@sanity/image-url";
import cx from "../cx";
import {imgStyles as styles} from "./img.treat";

const Img = ({asset, width, mode = "inline", className, ...rest}) => {
  const builder = urlBuilder({
    projectId: process.env.GATSBY_SANITY_PROJECT_ID,
    dataset: process.env.GATSBY_SANITY_DATASET,
  })
    .image(asset)
    .width(width)
    .fit("min")
    .auto("format");

  const x1 = builder.dpr(1).toString();
  const x2 = builder.dpr(2).toString();
  const srcSet = [`${x1} 1x`, `${x2} 2x`].join(", ");
  return (
    <img
      src={x1}
      srcSet={srcSet}
      alt=""
      className={cx(styles.base, className, styles[mode])}
      {...rest}
    />
  );
};

export default Img;
