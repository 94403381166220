const ROUTES = {
  home: "/",
};

/*
requires

 slug {
   current
 }
 withSpinner
 firstStep {
   slug {
     current
   }
 }
*/

export const routeToStation = (station) => {
  return `/station/${station.slug.current}/`;
};

export default ROUTES;
